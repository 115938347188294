import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation,useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';

const Form = () => {
  const location = useLocation();
  const { campaignName = '' } = location.state || {};
  const navigate = useNavigate();
  const [data, setData] = useState({});
  
  const [showMessage, setShowMessage] = useState(false);
  const [products, setProducts] = useState([{ name: '', quantity: '', ss: null, amount: '' }]);
  const [formData, setFormData] = useState({
    FullName: '',
    review: '',
    customMarketPlaceName: '',
    customForoum: '',
    whatsappGroup: '',
    city: '',

    AuthInstagram: '',
    AuthFacebook: '',
    AuthTwitter: '',
    AuthYouTube: '',
    gender: '',
    langaugeWrite: '',
    langaugeSpeek: '',
    college: '',
    whatsapp: '',
    email: '',
    date: '',
    manager: '',
    pinCode: '',
    accountNumber: '',
    ifsc: '',
    BeneficiaryName: '',
    BankName: '',
    productSS: '',
    inputValue: '',
    inputValue1: '',
    inputValue2: '',
    inputValue3: '',
    inputValue4: '',
    order: '',
    Phone: '',
    student: '',
    AuthCustomSocial: '',
    profileType: '',
    submitedDate: '',
    campaignName: '',
  });

  useEffect(() => {
    if (campaignName) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${Config.API_URL}/api/FormData/${campaignName}`);


          console.log('Fetched data:', response.data);


          const filteredData = response.data.reduce((acc, item) => ({ ...acc, ...item }), {});

          setData(filteredData);
        } catch (error) {
          console.error('Error fetching form data:', error);
        }
      };
      fetchData();
    }
  }, [campaignName]);

  const handleFormChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowMessage(true);
    const formDataToSubmit = {
      ...formData,
      student: formData.student === true, // Ensure it's a boolean
      campaignName,
  };
    try {
        const response = await axios.post('http://localhost:7000/api/order-data', formDataToSubmit);
        console.log('Data saved:', response.data);
        
        // Optionally navigate after a successful submission
        setTimeout(() => {
            navigate('/live-campaign');
        }, 1500);
    } catch (error) {
        console.error('Error saving data:', error);
    }
};
useEffect(() => {
  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${Config.API_URL}/api/product`, {
        params: { campaignName }, // Send campaign name as a query parameter
      });
      setProducts(response.data); // Set fetched products to state
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  if (campaignName) {
    fetchProducts(); // Fetch products when campaignName changes
  }
}, [campaignName]);
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // You can handle file processing or uploading logic here
      // For example, you can save the file to state or send it to a server
      console.log(file);
    }
  };
  let hasValNumber = /^[0-9]{1,10}$/.test(formData.Phone);
  let hasValWhatsapp = /^[0-9]{1,10}$/.test(formData.whatsapp);
  let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(formData.email);


  return (
    <div className='container'>
      <form onSubmit={handleSubmit}>
        <h2>Order Form for {campaignName}</h2>


        {data.FullName === true && (
          <div className="field">
            <label className="label">Your Full Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="FullName"
                placeholder="Name"
                value={formData.FullName}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.AuthInstagram === true && (
          <div className="field">
            <label className="label">Your Instagram User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="AuthInstagram"
                placeholder="Username"
                value={formData.AuthInstagram}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.AuthFacebook === true && (
          <div className="field">
            <label className="label">Your Facebook User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="AuthFacebook"
                placeholder="Username"
                value={formData.AuthFacebook}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.AuthTwitter === true && (
          <div className="field">
            <label className="label">Your Twitter User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="AuthTwitter"
                placeholder="Username"
                value={formData.AuthTwitter}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}

        {data.AuthYouTube === true && (
          <div className="field">
            <label className="label">Your YouTube User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name=" AuthYouTube"
                placeholder="Username"
                value={formData.AuthYouTube}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.email === true && (
          <div className="field">
            <label className="label"> Your Email</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="email"
                placeholder="abc@abc.com"
                value={formData.email}
                onChange={handleFormChange}
                required
              />
            </div>
            {formData.email && !hasValEmail && (
                            <div>
                                <small
                                    className={`ml-1 mt-1  ${!hasValNumber && formData.email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                    {!hasValNumber && formData.email !== '' ? 'Please Enter valid Mobile number' : ''}
                                </small>
                            </div>
                        )}
            
          </div>
        )}
        {data.Phone === true && (
          <div className="field">
            <label className="label">Your Phone Number</label>
            <div className="control">
            <input
  className="input is-rounded"
  type="text"
  name="Phone" // Removed the space before 'Phone'
  placeholder="9999999999"
  value={formData.Phone}
  onChange={handleFormChange}
  required
/>
            </div>
            {formData.Phone && !hasValNumber && (
                            <div>
                                <small
                                    className={`ml-1 mt-1  ${!hasValNumber && formData.Phone !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                    {!hasValNumber && formData.Phone !== '' ? 'Please Enter valid Mobile number' : ''}
                                </small>
                            </div>
                        )}
          </div>
        )}
        {data.whatsapp === true && (
          <div className="field">
            <label className="label"> Your Whatsapp Number</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="whatsapp"
                placeholder="9999999999"
                value={formData.whatsapp}
                onChange={handleFormChange}
                required
              />
            </div>
            {formData.whatsapp && !hasValWhatsapp && (
                            <div>
                                <small
                                    className={`ml-1 mt-1  ${!hasValNumber && formData.whatsapp !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                    {!hasValNumber && formData.whatsapp !== '' ? 'Please Enter valid Whatsapp Number number' : ''}
                                </small>
                            </div>
                        )}
          </div>
        )}
        {data.gender === true && (
          <div className="field">
            <label className="label">Your Gender</label>
            <div className="control">
              <label className="radio">
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  checked={formData.gender === 'male'}
                  onChange={handleFormChange}
                  required
                />
                Male
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={formData.gender === 'female'}
                  onChange={handleFormChange}
                />
                Female
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="gender"
                  value="other"
                  checked={formData.gender === 'other'}
                  onChange={handleFormChange}
                />
                Other
              </label>
            </div>
          </div>
        )}
        {data.langaugeSpeek === true && (
          <div className="field">
            <label className="label"> Which language can you speek?</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="langaugeSpeek"
                placeholder="eg- Hindi,English"
                value={formData.langaugeSpeek}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.langaugeWrite === true && (
          <div className="field">
            <label className="label"> Which language can you write?</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="langaugeWrite"
                placeholder="eg- Hindi,English"
                value={formData.langaugeWrite}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.langaugeSpeek === true && (
          <div className="field">
            <label className="label"> Which language can you speek?</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="langaugeSpeek"
                placeholder="eg- Hindi,English"
                value={formData.langaugeSpeek}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.order === true && (
          <div className="field">
            <label className="label"> Order Id</label>
            <div className="control">
            <input
  className="input is-rounded"
  type="text"
  name="order" // Removed the space before 'order'
  placeholder="order id"
  value={formData.order}
  onChange={handleFormChange}
  required
/>
            </div>
          </div>
        )}
        {data.date === true && (
          <div className="field">
            <label className="label"> Order Date</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="date"
                placeholder="dd-mm-yyyy"
                value={formData.date}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {/* Student Checkbox */}
        {data.student === true && (
          <div className="field">
            <label className="checkbox">
              <input
                type="checkbox"
                checked={formData.student || false}  // Bind checkbox to formData
                onChange={(e) => setFormData({ ...formData, student: e.target.checked })}
                required
              />
              Are you a Student?
            </label>
          </div>
        )}

        {/* Conditionally render College Name input if student is true and data.college is true */}
        {formData.student && data.college === true && (
          <div className="field">
            <label className="label">What is your College Name?</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="collegeName"  // New field for college name
                placeholder="College Name"
                value={formData.collegeName}  // Bind college name to formData
                onChange={handleFormChange}  // Use existing handleFormChange
                required
              />
            </div>
          </div>
        )}
        {data.pinCode === true && (
          <div className="field">
            <label className="label"> Pin Code</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="pinCode"
                placeholder="123456"
                value={formData.pinCode}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.accountNumber === true && (
          <div className="field">
            <label className="label">Bank Account Number</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="accountNumber"
                placeholder="Bank Account Number"
                value={formData.date}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.ifsc === true && (
          <div className="field">
            <label className="label"> Ifsc Code</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="ifsc"
                placeholder="Ifsc Code"
                value={formData.date}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.BeneficiaryName === true && (
          <div className="field">
            <label className="label"> Beneficiary Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="BeneficiaryName"
                placeholder=" Beneficiary Name"
                value={formData.BeneficiaryName}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.BankName === true && (
          <div className="field">
            <label className="label">Bank Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="BankName"
                placeholder="Bank Name"
                value={formData.BankName}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}

        {data.review && data.review !== false && data.review.trim() !== "" && (
          <div className='field'>
            <label className="label"> How Many Reviews you do per month</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="review"
                placeholder="Number of Reviews"
                value={formData.review}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.AuthCustomSocial && data.AuthCustomSocial !== false && data.AuthCustomSocial.trim() !== "" && (
          <div className='field'>
            <label className="label">  Your {data.AuthCustomSocial} User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="AuthCustomSocial"
                placeholder="User Name"
                value={formData.AuthCustomSocial}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}


        {data.profileType && data.profileType !== false && data.profileType.trim() !== "" && (
          <div className='field'>
            <label className="label">Is your profile type {data.profileType}?</label>
            <div className="control">
              <label className="radio">
                <input
                  type="radio"
                  name="profileType"
                  value="yes"
                  checked={formData.profileType === 'yes'}
                  onChange={handleFormChange}
                />
                Yes
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="profileType"
                  value="no"
                  checked={formData.profileType === 'no'}
                  onChange={handleFormChange}
                />
                No
              </label>
            </div>
          </div>
        )}

        {data.submitedDate && data.submitedDate !== false && data.submitedDate.trim() !== "" && (
          <div className='field'>
            <label className="label">Submited Date {data.submitedDate}</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="submitedDate"
                placeholder="dd/mm/yyyy"
                value={formData.submitedDate}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {/* Only render if customMarketPlaceName is true */}
        {data.customMarketPlaceName && data.customMarketPlaceName !== false && data.customMarketPlaceName.trim() !== "" && (
          <div className='field'>
            <label className="label">{data.customMarketPlaceName} Account Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="customMarketPlaceName"
                placeholder="Account Name"
                value={formData.customMarketPlaceName}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}

        {/* Only render if customForoum is true */}
        {data.customForoum && data.customForoum !== false && data.customForoum.trim() !== "" && (
          <div className='field'>
            <label className="label">{data.customForoum} Profile Link</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="customForoum"
                placeholder="Profile Link"
                value={formData.customForoum}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.productSS === true && (
          <div className='field'>
            {/* Additional input field */}
            <div className='field'>
  <label className="label">{data.inputValue}</label>
  <div className="control">
    <div className="select is-rounded">
      <select
        name="inputValue"
        value={formData.inputValue}
        onChange={handleFormChange}
        required
      >
          <option value="" disabled>Select a product</option>
            {products.map((product) => (
              <option key={product._id} value={product.productName}>
                {product.productName}
              </option>
        ))}
      </select>
    </div>
  </div>
</div>

            <div className='field'>
              <label className="label">{data.inputValue1}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  name="inputValue1"
                  placeholder="Input Value"
                  value={formData.inputValue1}
                  onChange={handleFormChange}
                  required
                />
              </div>
            </div>
            <div className='field'>
              <label className="label">{data.inputValue2}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  name="inputValue2"
                  placeholder="Input Value"
                  value={formData.inputValue2}
                  onChange={handleFormChange}
                  required
                />
              </div>
            </div>
            <div className='field'>
              <label className="label">{data.inputValue3}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="file"
                  name="inputValue3"
                  accept="image/*" // Only allow image files
                  onChange={handleFileUpload} // Use a separate handler for file uploads
                  required
                />
              </div>
            </div>
            <div className='field'>
              <label className="label">{data.inputValue4}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  name="inputValue4"
                  placeholder="Input Value"
                  value={formData.inputValue4}
                  onChange={handleFormChange}
                  required
                />
              </div>
            </div>
          </div>
        )}

        {/* Only render if whatsappGroup is true */}
        {data.whatsappGroup === true && (
          <div className="field">
            <label className="label">Have You Joined Whatsapp Group</label>
            <label className="radio">
              <input
                type="radio"
                name="whatsappGroup"
                value="yes"
                checked={formData.whatsappGroup === 'yes'}
                onChange={handleFormChange}
                required
              />
              Yes
            </label>
            <label className="radio">
              <input
                type="radio"
                name="whatsappGroup"
                value="no"
                checked={formData.whatsappGroup === 'no'}
                onChange={handleFormChange}
                required
              />
              No
            </label>
          </div>
        )}

        {/* Only render if city is true */}
        {data.city === true && (
          <div className='field'>
            <label className="label">City</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"

            />
            Agree to{' '}
            <a href="//hobo.video/terms-conditions-for-an-influencer" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </label>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-primary is-fullwidth mt-5" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
      {showMessage && (
        <div className="thank-you-message">
          <h1>Form Submited successfully</h1>
          <p>Thank you for submitting the form!</p>
        </div>
      )}
    </div>
  );
};

export default Form;
