import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
// import logo from "../Logo (1).png";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
// import Table from "../masterTable";
import CampaignForm from "../CampaignCreateForm";
import AA from "../AA";
import Form from '../orderForm';
import Live from '../live';
import Table from '../orderPaymentAprroverTable'
// import InviteForm from '../pages/userRole';
// import Modal from '../pages/invite';
// import UserTable from '../pages/userTable';

library.add(fas);
const LeadDashboard = () => {
    const [showCreateCampaignForm, setShowCreateCampaignForm] = useState(false);
    const [showBrandApplications, setShowBrandApplications] = useState(false);
    const [showInfluencerApplications, setShowInfluencerApplications] = useState(false);
    const [showShortlistedInfluencers, setShowShortlistedInfluencers] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [WhatsappNumber, setWhatsappNumber] = useState("");
    const [UserName, setUserName] = useState("");
    const [Email, setemail] = useState("");
    const [MobileNumber, setmobilenumber] = useState("");

    const hasValNumber = /^[0-9]{1,10}$/.test(PhoneNumber || MobileNumber);
    const hasValEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(Email);

    const tableData = [["Data 1", "Data 2", "data3"]];

    const generateCSVData = () => {
        const headers = Array.from(document.querySelectorAll(".table th")).map(
            (th) => th.innerText
        );
        const csvData = [headers, ...tableData];
        return csvData;
    };

    const statusOptions = ["Selected", "Rejected", "On Hold"];
    const [shipmentStatus, setShipmentStatus] = useState({});

    const handleCheckboxChange = (index, status) => {
        setShipmentStatus((prevShipmentStatus) => ({
            ...prevShipmentStatus,
            [index]: status,
        }));
    };

    const [trackingIds, setTrackingIds] = useState({});
    const handleTrackingIdChange = (index, trackingId) => {
        setTrackingIds((prevTrackingIds) => ({
            ...prevTrackingIds,
            [index]: trackingId,
        }));
    };

    const handleStatusChange = (index, selectedStatus) => {
        console.log(`Status changed for row ${index + 1}: ${selectedStatus}`);
    };

    const handleApproval = (index) => {
        console.log(`Deliverables approved for row ${index + 1}`);
    };

    const [filterStatus, setFilterStatus] = useState("all");
    const data = generateCSVData();
    const filteredData = data.filter((item) => {
        if (filterStatus === "all") return true;
        return item.status === filterStatus;
    });

    const [showUserRoles, setShowUserRoles] = useState(false);

    const handleUserRolesClick = () => {
        setShowUserRoles(true);
        setShowAllCampaign(false);
        setShowCreateCampaignForm(false);
        setShowBrandApplications(false);
    };

    const [showInviteForm, setShowInviteForm] = useState(false);
    const handleInviteTeamMembersClick = () => {
        setShowInviteForm(true);
        setShowUserRoles(false);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setShowShortlistedInfluencers(false);
    };

    const [showAllCampaign, setShowAllCampaign] = useState(true);
    const handleShowAllCampignClick = () => {
        setShowAllCampaign(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setShowShortlistedInfluencers(false);
    };

    const [showDeliverables, setShowDeliverables] = useState(false);
    const handleDeliverbralesClick = () => {
        setShowDeliverables(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setShowUserRoles(false);
    };

    const [showConformation, setShowConformation] = useState(false);
    const handleConformationClick = () => {
        setShowConformation(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
    };

    const handleCreateNewCampaignClick = () => {
        setShowCreateCampaignForm(true);
        setShowBrandApplications(false);
        setShowInfluencerApplications(false);
        setShowShortlistedInfluencers(false);
    };

    const handleShortlistedInfluencersClick = () => {
        setShowShortlistedInfluencers(true);
        setShowCreateCampaignForm(false);
        setShowBrandApplications(false);
        setShowInfluencerApplications(false);
    };

    const location = useLocation();
    const brandName = location.state ? location.state.brandName : null;

    const [selectedBrand, setSelectedBrand] = useState(null);

    const handleBrandClick = (brand) => {
        setSelectedBrand(brand);
    };

    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const handleMouseEnter = () => {
        setIsActive(true);
    };

    const handleMouseLeave = () => {
        setIsActive(false);
    };

    // const [selectedOption, setSelectedOption] = useState('');
  
    return (
        // <div className='imgfull'>
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                {/* Sidebar */}
                <a className="navbar-item" >
                    {/* <span className="icon mr-3">
                        // <img className="ml-5" src="./images/hobo.jpeg" alt="Menu Icon" />
                    </span> */}
                </a>
            </div>
            <div >

                 {/* <div style={{ marginRight: "70pxpx" }} className={`hamburger ${isActive ? 'is-active' : ''}`} onClick={toggleMenu}> */}
                    <span className="icon mr-3">
                        <img src="./images/hobo.jpeg" alt="Menu Icon" style={{ marginRight: "30px" }} />
                    </span> 
                    
                {/* <div className= has-background-info' style={{marginRight:"10rem"}}> */}
               <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} style={{ backgroundColor: 'dark-blue' }}>
                    <aside className={` menu  ${isActive ? '' : 'is-hidden'} overflow-hidden`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <ul className="menu-list d-flex flex-row-reverse" >
                            <div className=' has-text-left ml-5'  >
                                <figure className="image is-32x32" >
                                {/* <img src={logo} alt="Logo" />   */}
                                </figure>
                                {/* <li className="menu-label " style={{ marginTop: "1rem", marginLeft: "4px" }}>
                                    Brand Dashboard
                                </li> */}
                               
                                <li className='my-5 has-text-white' onClick={handleShortlistedInfluencersClick}>
                                    <MenuItem title="Table for influencers" icon="fas fa-plus" />
                                </li>
                              
                                
                          
                            </div>
                        </ul>
                    </aside>
                    </div>

                </div >



                <ul className="menu1   d-flex flex-row-reverse"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {!isActive && (
                        <div className=' has-text-centered '>
                            <figure className="image is-32x32" >
                                
                            </figure>
                           
                           

                            {!isActive && (
                                <div className='has-text-white'>
                                 
                                </div>

                            )}
                        </div>
                    )} 
                     {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer", }}>

                            <span className="icon is-small has-text-success">
                                <i className="fas fa-cog" style={{ marginRight: "90px" }} ></i>
                            </span>

                        </li>
                    )}
                </ul>
            {/* </div > */}


            {/* Main content */}
            < div className="column" >
                {/* <h1>Welcome to {brandName} Dashboard</h1> */}
                <div className="columns is-ancestor">
                    <div className="column  mt-5">

                        {showCreateCampaignForm ? (
                            // Render the Create Campaign form
                            <div className="is-child  has-text-centered" style={{ height: '100vh', width: '50vw', marginLeft: '250px' }}>

                             <CampaignForm/>
                             
                            </div>
                     

                            //1o651InfluencerApplications
                      

                        ) : showShortlistedInfluencers ? (
                            <div className='full-page-container' style={{ display: 'flex', height: '100vh',marginLeft:"130px",marginTop: "-30px" }}>
                            <div className='is-child' style={{ height: '150vh', width:'83vw', alignItems: 'center' }}>
                                <div style={{ overflowY: 'auto', flexGrow: 1 }}>
                                    <Table />
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>

                        ) : showDeliverables ? (
                            <div className='is-child box has-text-centered' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                <h1 className='subtitle mt-5 underline'>
                                </h1>
                                {/* <InfluencerDeliverblesTable /> */}
                               
                                <div></div>
                            </div>
                        ) : showConformation ? (
                            <div className='is-child box has-text-centered' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                <h1 className='subtitle mt-5 underline'>
                                </h1>
                                {/* <InfluencerConformationTable /> */}
                               
                            </div>
                            // showuserRoles
                       
                        ) : showInviteForm ? (
                            // Display the invite form
                            <div className='is-child box has-text-centered'>
                                <h1 className='subtitle mt-5'>Invite form campaign
                                </h1>
                                <div className='form'>

                                    <div className='field '>
                                        <div className="control has-icons-left">
                                            <div className='colomn'>
                                                <input className="input is-rounded "
                                                    type="text"
                                                    placeholder="Email ID"
                                                    value={Email}
                                                    onChange={(e) => setemail(e.target.value)}
                                                />
                                                {Email && !hasValEmail && (
                                                    <small
                                                        className={`ml-1 mt-1  ${!hasValEmail && Email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                                        {!hasValEmail && Email !== '' ? ('Enter a valid email address') : ''}
                                                    </small>
                                                )}
                                                <span className="icon is-small is-left">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* mobile number */}
                                    <div className='field '>
                                        <div className="control has-icons-left ">
                                            <div className='colomn'>
                                                <input className="input  is-rounded "
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    value={MobileNumber}
                                                    onChange={(e) => setmobilenumber(e.target.value)}
                                                />

                                                {/* mobile number validation */}
                                                {MobileNumber && !hasValNumber && (
                                                    <div>
                                                        <small
                                                            className={`ml-1 mt-1  ${!hasValNumber && MobileNumber !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                                            {!hasValNumber && MobileNumber !== '' ? 'Please Enter valid Mobile number' : ''}
                                                        </small>
                                                    </div>
                                                )}

                                                <span className='icon is-small is-left'>
                                                    <i className='fas fa-phone'></i>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="field">
                                        <div className="control">

                                            <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth " disabled={!MobileNumber || !Email || !hasValNumber || !hasValEmail}>
                                                SIGN UP
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        ) : (
                            <div className='subtitle' style={{ fontSize: '15px', marginLeft: "90px" }}>

                              
                            </div>

                        )}

                    </div>
                </div>
            </ div>

        </nav >
        // </div>
    )
}
function MenuItem({ title, icon, clickHandler }) {
    return (
        <li className="ml-2 my-2" style={{ cursor: "pointer" }} onClick={clickHandler}>
            <span className="icon is-small has-text-success mr-1">
                <i className={icon} style={{ marginRight: "50px" }}></i>
            </span>
            <span style={{ marginLeft: "-25px" }}>{title}</span>
        </li>
    );
}

export default LeadDashboard;
