import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';
import OrderForm from '../pages/orderForm.js';
import Refund from '../pages/refundForm.js';
import Table from './masterTable.js';
import OrderTable from './orderTable.js'

const AllCamp = ({ campaign="Hello", alignLeft }) => {
    const navigate = useNavigate();

    if (!campaign) {
        console.error('Campaign is undefined'); // Debugging log
        return null;
    }

    const { campaignName, managers } = campaign;

    const backgroundImageStyle = {
        backgroundImage: `url(${Config.API_URL}/uploads/${encodeURIComponent(campaignName)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 500,
        width: 550,
        display: 'flex',
        flex: 1,
        backgroundBlendMode: 'multiply'
    };

    const handleApply = () => {
        navigate(`/order-table`, { state: { campaignName } });
    };

    const handleApplyDeliverables = () => {
        navigate(`/order-form`, { state: { campaignName } });
        
    };

    const handleConfirmation = () => {
        navigate(`/refund-form`,{ state: { campaignName } } );
    };

    const handleOrders = () => {
        navigate(`/order-table`, { state: { campaignName } });
    };

    return (
        <div className='is-max-desktop'>
            <div className="card mt-6 mr-5" style={backgroundImageStyle}>
                <div className="card-content" style={{ flex: 1, height: 500, width: 100 }}>
                    <div className="box" style={{ marginLeft: 146, width: 380, height: 450, overflowX: 'hidden' }}>
                        <br />
                        <h1 className='subtitle'><b>{campaignName}</b></h1>
                        <p>Campaign Title: {campaignName} </p>
                        <p>Brand Name: </p>
                        <p>Deliverables: </p>
                        <p>Give Product Worth Rs: </p>
                        <div>
                            <button onClick={handleApply} className="button is-small-custom  mt-3 ml-1 has-text-info is-rounded">View Campaign</button>
                            <button onClick={handleApplyDeliverables} className="button is-small-custom  mt-3 ml-1 has-text-info is-rounded">View Order Form</button>
                            <button onClick={handleConfirmation} className="button is-small-custom  mt-2 ml-1 has-text-info is-rounded">View Refund Form</button>
                            <button onClick={handleOrders} className="button is-small-custom  mt-2 ml-1 has-text-info is-rounded">View Orders</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllCamp;